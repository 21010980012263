import React, {useState} from '../../node_modules/react';
import Button from 'react-bootstrap/Button';
import { updateword } from "../graphql/queries";
import { API, graphqlOperation } from 'aws-amplify';

function IgnoreButton(props) {
    const [ disabled, setDisabled ] = useState(false);
    const { word, sourceLanguage, savedWordsObject } = props;

    // const ignoreWord = async () => {
    //     setDisabled(true)
    //     try{
    //         const newWord = await API.graphql(graphqlOperation(createWord, {input: {word: word.toLowerCase(), category: 'i' , language: sourceLanguage}}))
    //         const updatedSessions = savedWordsObject
    //         updatedSessions[word.toLowerCase()] = {'category': 'i', 'id': newWord.id};
    //         props.setSavedWordsObject(JSON.parse(JSON.stringify(updatedSessions)));
    //     } catch (err) {
    //         console.log('error: ', err);
    //     }
    //     setDisabled(false)
    // }

    const ignoreWord = async () => {
        setDisabled(true);
        try {
          const lowerCase = word.toLowerCase();
          const response = await API.graphql(
            graphqlOperation(updateword, { 
              input: JSON.stringify(
                { id: savedWordsObject[lowerCase] ? savedWordsObject[lowerCase].id : null, 
                  word: lowerCase, category: "i", 
                  language: sourceLanguage 
                }) 
              })
          );
          
          const newWordId = JSON.parse(response.data.updateword).returnWordId;
    
          const updatedWords =  JSON.parse(JSON.stringify(savedWordsObject))
          updatedWords[word.toLowerCase()] = {
            category: "i",
            id: newWordId,
          };
          props.setSavedWordsObject(updatedWords);
            
        } catch (e) {
          console.log(e);
          setDisabled(false);
        }
      }

    return (
        <Button disabled={disabled} variant={'secondary'} onClick={ignoreWord}  data-amplify-analytics-on='click' data-amplify-analytics-name='ignore-word' data-amplify-analytics-attrs={`language:${sourceLanguage}`}>Ignore</Button>
    );
  }

export default IgnoreButton;