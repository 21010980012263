import React from 'react';

function Footer(){
    return (
        <footer className={"page-footer font-small blue bg-dark"}>
            
        </footer>
    )
}

export default Footer