import React, { useState, useEffect } from "react";
import Amplify, { API, graphqlOperation } from "aws-amplify";
import config from "./aws-exports";
import { AmazonAIPredictionsProvider } from "@aws-amplify/predictions";
import Sections from "./components/Sections";
import Sidebar from "./components/Sidebar";
import TutorialModal from "./components/TutorialModal";
import LimitReachedModal from "./components/LimitReachedModal";
import FetchingDictionary from "./components/FetchingDictionary";
import ManageAccount from "./components/ManageAccount";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import getSampleText from "./functions/getSampleText";
//import { listWords, wordsByOwner } from "./graphql/queries";

import { listuserwords } from "./graphql/queries";

import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

var urlsIn = config.oauth.redirectSignIn.split(",");
var urlsOut = config.oauth.redirectSignOut.split(",");
const oauth = {
  domain: config.oauth.domain,
  scope: config.oauth.scope,
  redirectSignIn: config.oauth.redirectSignIn,
  redirectSignOut: config.oauth.redirectSignOut,
  responseType: config.oauth.responseType,
};
var hasLocalhost = (hostname) =>
  Boolean(
    hostname.match(/localhost/) ||
      hostname.match(/127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}/)
  );
var hasHostname = (hostname) =>
  Boolean(hostname.includes(window.location.hostname));
var isLocalhost = hasLocalhost(window.location.hostname);
if (isLocalhost) {
  urlsIn.forEach((e) => {
    if (hasLocalhost(e)) {
      oauth.redirectSignIn = e;
    }
  });
  urlsOut.forEach((e) => {
    if (hasLocalhost(e)) {
      oauth.redirectSignOut = e;
    }
  });
} else {
  urlsIn.forEach((e) => {
    if (hasHostname(e)) {
      oauth.redirectSignIn = e;
    }
  });
  urlsOut.forEach((e) => {
    if (hasHostname(e)) {
      oauth.redirectSignOut = e;
    }
  });
}
var configUpdate = config;
configUpdate.oauth = oauth;

Amplify.addPluggable(new AmazonAIPredictionsProvider());
Amplify.configure(configUpdate);

function App(props) {
  const {
    sourceLanguage,
    targetLanguage,
    view,
    setView,
    premium,
    showTutorial,
    setShowTutorial,
    fetchingDictionary,
    setFetchingDictionary,
    currentUserData,
  } = props;
  const [sections, setSections] = useState([]);
  const [savedWordsObject, setSavedWordsObject] = useState({});
  const [savedTranslationsObject, setSavedTranslationsObject] = useState({});
  const [showLimitReachedModal, setShowLimitReachedModal] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    async function getWords() {
      setFetchingDictionary(true);
      try {
        let allWords = [];

        let response = await API.graphql(
          graphqlOperation(listuserwords, {
            input: JSON.stringify({ language: sourceLanguage }),
          })
        );

        let parsedResponse = JSON.parse(response.data.listuserwords);

        allWords = allWords.concat(parsedResponse.words);

        if (parsedResponse.limitReached) {
          setShowLimitReachedModal(true);
        } else {
          while (parsedResponse.LastEvaluatedKey) {
            let response = await API.graphql(
              graphqlOperation(listuserwords, {
                input: JSON.stringify({
                  language: sourceLanguage,
                  ExclusiveStartKey: parsedResponse.LastEvaluatedKey,
                }),
              })
            );
            parsedResponse = JSON.parse(response.data.listuserwords);

            allWords = allWords.concat(parsedResponse.words);
          }
        }

        const savedWords = allWords.reduce((wordDictionary, currentWord) => {
          wordDictionary[currentWord.word.S] = {
            category: currentWord.category.S,
            id: currentWord.id.S,
          };
          return wordDictionary;
        }, {});
        setSavedWordsObject(savedWords);
        setFetchingDictionary(false);
      } catch (err) {
        console.log("error: ", err);
      }
    }

    getWords();
  }, [sourceLanguage, setFetchingDictionary]);

  const sample = sections.length === 0 ? getSampleText(sourceLanguage) : "";

  return (
    <Container
      className={
        "gradient-background no-padding no-margin full-width window-fill"
      }
    >
      <Row
        className={"no-margin"}
        style={{ maxHeight: "100%", height: "100%" }}
      >
        {view === "app" ? (
          fetchingDictionary ? (
            <FetchingDictionary sourceLanguage={sourceLanguage} />
          ) : (
            <>
              <Col xl={7} className={"no-padding main-content-row"}>
                <Sections
                  sections={sections}
                  setSections={setSections}
                  targetLanguage={targetLanguage}
                  sourceLanguage={sourceLanguage}
                  savedWordsObject={savedWordsObject}
                  setSavedWordsObject={setSavedWordsObject}
                  savedTranslationsObject={savedTranslationsObject}
                  setSavedTranslationsObject={setSavedTranslationsObject}
                  sample={sample}
                  submitting={submitting}
                  setSubmitting={setSubmitting}
                  setShowLimitReachedModal={setShowLimitReachedModal}
                  showTutorial={showTutorial}
                  showLimitReachedModal={showLimitReachedModal}
                />
              </Col>
              <Col xl={5} className={"no-padding"}>
                <div className="sidebar-window">
                  <Sidebar
                    sections={
                      sections.length === 0 ? [{ text: sample }] : sections
                    }
                    savedWordsObject={savedWordsObject}
                    setSavedWordsObject={setSavedWordsObject}
                    sourceLanguage={props.sourceLanguage}
                    targetLanguage={props.targetLanguage}
                    savedTranslationsObject={savedTranslationsObject}
                    setSavedTranslationsObject={setSavedTranslationsObject}
                    submitting={submitting}
                    setSubmitting={setSubmitting}
                  />
                </div>
              </Col>
              <TutorialModal
                showTutorial={showTutorial}
                setShowTutorial={setShowTutorial}
              />
              <LimitReachedModal
                showLimitReachedModal={showLimitReachedModal}
                setShowLimitReachedModal={setShowLimitReachedModal}
                premium={premium}
                currentUserData={currentUserData}
              />
            </>
          )
        ) : (
          <ManageAccount
            setView={setView}
            premium={premium}
            currentUserData={currentUserData}
          />
        )}
      </Row>
    </Container>
  );
}

export default App;
