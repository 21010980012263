import * as React from 'react';
import { Loading } from "aws-amplify-react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import logo from '../logo.svg';

class CustomLoading extends Loading {
  constructor(props: any) {
    super(props);
    this._validAuthStates = ["loading"];
  }

  showComponent(theme: any) {
    return (
      <Container className='window-fill gradient-background no-padding no-margin full-width'>
        <Row className="justify-content-md-center" style={{height: '100%'}}>
            <Col md={6} className={'align-self-center'}>
              <Container className={'form-title'}>
                <Row>
                  <Col xs={12} className={'my-auto'}>
                    <img alt="" src={logo} style={{height: '20vw', width: '20vw'}} className="d-inline-block align-top" />
                  </Col>
                  <Col xs={12} style={{color: 'white', fontSize: '10vw'}}>
                    Lingodi
                  </Col>
                </Row>
              </Container>
            </Col>
        </Row>
    </Container>
      
    )
  }
}

export default CustomLoading;