import React from "react";
import Button from "react-bootstrap/Button";

function UpdateWordButton(props) {
  const { text, variant, word, updateWord, category, sourceLanguage, submitting } = props;

  const handleClick = async () => {
    await updateWord(word,category);
  }

  return (
    <Button disabled={submitting} variant={variant} onClick={handleClick} data-amplify-analytics-on='click' data-amplify-analytics-name='learn-word' data-amplify-analytics-attrs={`language:${sourceLanguage}`}>
      {text}
    </Button>
  );
}

export default UpdateWordButton;
