import React, {
  useState,
  useRef,
  useLayoutEffect,
} from "../../node_modules/react";
import ProgressBar from "react-bootstrap/ProgressBar";
import ConfettiExplosion from "@reonomy/react-confetti-explosion";

function LearningProgressBar(props) {
  const { percentageLearned, suppressConfetti } = props;

  const [explosion, setExplosion] = useState(false);

  const firstUpdate = useRef(true);

  useLayoutEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    if (percentageLearned === 100 && !suppressConfetti) {
      setExplosion(true);
      setTimeout(function () {
        setExplosion(false);
      }, 3000);
    } else {
      setExplosion(false);
    }
  }, [percentageLearned]);

  return (
    <div>
      <ProgressBar now={percentageLearned} label={`${percentageLearned}%`} />
      {explosion && <ConfettiExplosion />}
    </div>
  );
}

function areEqual(prevProps, nextProps) {
  return prevProps.percentageLearned === nextProps.percentageLearned;
}

export default React.memo(LearningProgressBar, areEqual);
