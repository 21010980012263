import React from "../../node_modules/react";
import CommonWordsTable from "./CommonWordsTable";
import LearningProgressBar from "./LearningProgressBar";
import YourDictionary from "./YourDictionary";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import wordRegex from "../regex/wordRegex";
import { calculatePercentageLearned } from "../functions/calculatePercentageLearned";

function Sidebar(props) {
  const {
    sections,
    savedWordsObject,
    setSavedWordsObject,
    sourceLanguage,
    targetLanguage,
    savedTranslationsObject,
    setSavedTranslationsObject,
    submitting,
    setSubmitting,
  } = props;

  const allText = props.sections.map((section) => section.text).join(" ");
  const words = allText.match(wordRegex);

  const percentageLearned = calculatePercentageLearned(words, savedWordsObject);

  return (
    <div className="sidebar shadow">
      <Tabs defaultActiveKey={"explorer"}>
        <Tab eventKey="explorer" title="Content Explorer">
          {props.sections.length > 0 ? (
            <Container>
              <Row className={"pt-2"}>
                <Col xs={12} className={"text-center"}>
                  <h3>% of words in your dictionary</h3>
                </Col>
              </Row>
              <Row className={"mb-4"}>
                <Col xs={12}>
                  <LearningProgressBar
                    percentageLearned={percentageLearned}
                    suppressConfetti={true}
                  />
                </Col>
              </Row>

              <Row>
                <Col xs={12} className={"mb-3"}>
                  <CommonWordsTable
                    words={words}
                    sections={sections}
                    savedWordsObject={savedWordsObject}
                    setSavedWordsObject={setSavedWordsObject}
                    sourceLanguage={sourceLanguage}
                    targetLanguage={targetLanguage}
                    savedTranslationsObject={savedTranslationsObject}
                    setSavedTranslationsObject={setSavedTranslationsObject}
                    submitting={submitting}
                    setSubmitting={setSubmitting}
                  />
                </Col>
              </Row>
            </Container>
          ) : (
            ""
          )}
        </Tab>
        <Tab eventKey="dictionary" title="Your Dictionary">
          <YourDictionary
            savedWordsObject={savedWordsObject}
            sourceLanguage={sourceLanguage}
            setSavedWordsObject={setSavedWordsObject}
          />
        </Tab>
      </Tabs>
    </div>
  );
}

export default Sidebar;
