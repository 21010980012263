import punctuationRegex from "../regex/punctuationRegex";

const calculatePercentageLearned = (words, savedWordsObject) => {
  let totalWords = 0;
  let learnedWords = 0;
  words.forEach((word) => {
    if (!word.match(punctuationRegex)) {
      const inSaved = savedWordsObject[word.toLowerCase()];
      totalWords += !inSaved || inSaved["category"] === "l" ? 1 : 0;
      learnedWords += inSaved && inSaved["category"] === "l" ? 1 : 0;
    }
  });
  let percentageLearned =
    totalWords === 0 ? 100 : Math.round((learnedWords / totalWords) * 100);
  return percentageLearned;
};

export { calculatePercentageLearned };
