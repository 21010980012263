import React from "../../node_modules/react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import TutorialStepper from "./TutorialStepper";

function TutorialModal(props) {
  const { showTutorial, setShowTutorial } = props;

  const handleClose = () => setShowTutorial(false);
  //<Modal.Header closeButton>
  return (
    <Modal show={showTutorial} onHide={handleClose} size="lg" backdrop="static">
      <Modal.Header>
        <Modal.Title style={{ userSelect: "none" }}>
          How to use Lingodi
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <TutorialStepper />
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={handleClose}
          data-amplify-analytics-on="click"
          data-amplify-analytics-name="tutotorial-close"
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default TutorialModal;
