import Predictions from '@aws-amplify/predictions';

const translate = (textToTranslate,sourceLanguage,targetLanguage,stateUpdateFunction,savedTranslationsObject,setSavedTranslationsObject) => {
    Predictions.convert({
      translateText: {
        source: {
          text: textToTranslate,
          language : sourceLanguage // defaults configured on aws-exports.js
          // supported languages https://docs.aws.amazon.com/translate/latest/dg/how-it-works.html#how-it-works-language-codes
        },
        targetLanguage: targetLanguage
      }
    }).then(result => {
      stateUpdateFunction(result.text);
      const newSavedTranslationsObject = savedTranslationsObject;
      if(!newSavedTranslationsObject[targetLanguage]){
        newSavedTranslationsObject[targetLanguage] = {};
      }
      newSavedTranslationsObject[targetLanguage][textToTranslate] = result.text;
      setSavedTranslationsObject(newSavedTranslationsObject);
    })
      .catch(err => console.log(JSON.stringify(err, null, 2)))
  }

export default translate;