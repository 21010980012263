import React, {useState} from '../../node_modules/react';
import Button from 'react-bootstrap/Button';
import { updateword } from "../graphql/queries";
import { API, graphqlOperation } from 'aws-amplify';

function UnlearnButton(props) {
    const [ disabled, setDisabled ] = useState(false);
    const { savedWordsObject, setSavedWordsObject, word } = props;
    
    // const unlearnWord = async () => {
    //     setDisabled(true);
    //     try {
    //         await API.graphql(graphqlOperation(deleteWord, { input: {id: wordId } } ) )
    //         const updatedWords = {...savedWordsObject}
    //         delete updatedWords[word.toLowerCase()];
    //         setSavedWordsObject(updatedWords);
    //     } catch (err) {
    //         console.log('error: ', err);
    //     }
    //     setDisabled(false);
    // }

    const unlearnWord = async () => {
        setDisabled(true);
        try {
          const lowerCase = word.toLowerCase();
          await API.graphql(
            graphqlOperation(updateword, { 
              input: JSON.stringify({ id: savedWordsObject[lowerCase] ? savedWordsObject[lowerCase].id : null}) 
              })
          );
          const updatedWords =  JSON.parse(JSON.stringify(savedWordsObject))
          delete updatedWords[word.toLowerCase()];
          setSavedWordsObject(updatedWords);
            
        } catch (e) {
          console.log(e);
          setDisabled(false);
        }
      }

    return (
        <Button disabled={disabled} variant={'danger'} onClick={unlearnWord}  data-amplify-analytics-on='click' data-amplify-analytics-name='unlearn-word'>Unlearn</Button>
    );
  }

export default UnlearnButton;