import React, { useState } from "../../node_modules/react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";

import StripeComponents from "./StripeComponents";
import { API, graphqlOperation } from "aws-amplify";
import { batchDeleteWords } from "../graphql/mutations";

function ConfirmDictionaryResetModal(props) {
  const { show, setShow } = props;

  const handleClose = () => setShow(false);

  const TESTdeleteWords = async () => {
    /*
        const deletes = await API.graphql(
            graphqlOperation(batchDeleteWords, {
                ids: ["df520efa-38cc-41f3-a565-f751b3381af0"],
            })
        );
        */
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Are you sure?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>You won't be able to undo this action.</p>
        <p>Click cancel if you do not wish to proceed.</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="success" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="danger" onClick={handleClose}>
          Reset My Dictionary
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

function ManageAccount(props) {
  const { setView, premium, currentUserData } = props;
  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);

  return (
    <Container>
      <Row>
        <Col>
          <Button variant={"link"} onClick={() => setView("app")}>
            {"<"} Back to the content explorer
          </Button>
        </Col>
      </Row>
      <Row className={"mt-3 mb-3"}>
        <Col className="text-center">
          <h1>Membership Plans</h1>
        </Col>
      </Row>
      <Row>
        <Col>
          <StripeComponents
            premium={premium}
            currentUserData={currentUserData}
          />
        </Col>
      </Row>

      {/*<Row className={"mt-5 mb-3"}>
        <Col className="text-center">
          <h1>Your Account</h1>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6}>
          Reset your dictionary{" "}
          <span style={{ color: "red" }}>(This action cannot be undone)</span>
        </Col>
        <Col xs={12} md={6}>
          <Button variant={"danger"} onClick={handleShow}>
            Reset Your Dictionary
          </Button>
        </Col>
      </Row>
      <ConfirmDictionaryResetModal show={show} setShow={setShow} />*/}
    </Container>
  );
}

export default ManageAccount;
