import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import DictionaryTable from './DictionaryTable';
import setLanguageIcon from '../functions/setLanguageIcon';

function YourDictionary(props){
    const { savedWordsObject, sourceLanguage, setSavedWordsObject } = props;

    const { wordsLearned, wordsIgnored } = Object.values(savedWordsObject).reduce((object,word) => {
        if(word.category === 'l'){
            object.wordsLearned += 1
        }
        if(word.category === 'i'){
            object.wordsIgnored += 1
        }
        return object;
    },{wordsLearned: 0, wordsIgnored: 0})
    
    const getLanguageName = (languageCode) => {
        switch(languageCode) {
            case 'es':
                return "Spanish";
            case 'fr':
                return 'French';
            case 'de':
                return 'German';
            case 'it':
                return 'Italian';
            case 'ru':
                return 'Russian';
            default:
                return "English"
        }
    }

    const numberWithCommas = (x) => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    return (
        <Container>
            <Row className={'pt-2'}>
                <Col xs={12} className={'text-center'}>
                    <h3>{getLanguageName(sourceLanguage)} <img src={setLanguageIcon(sourceLanguage)} alt={''} className={'language-icon-lg'}/></h3>
                </Col>
            </Row>
            <Row className={'pt-2'}>
                <Col xs={6}>
                    <h4>Words in your dictionary:</h4>
                </Col>
                <Col xs={6} className={'text-center'}>
                    <h3>{numberWithCommas(wordsLearned)}</h3>
                </Col>
            </Row>
            <Row className={'pt-2'}>
                <Col xs={6}>
                    <h4>Words ignored:</h4>
                </Col>
                <Col xs={6} className={'text-center'}>
                    <h3>{numberWithCommas(wordsIgnored)}</h3>
                </Col>
            </Row>
            <Row>
                <Col xs={12} className={'mb-3'}>
                    <DictionaryTable 
                        savedWordsObject={savedWordsObject} 
                        setSavedWordsObject={setSavedWordsObject} 
                        sourceLanguage={sourceLanguage} 
                    />
                </Col>
            </Row>
        </Container>
    )
}

export default YourDictionary;