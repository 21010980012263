import React, { useState, useEffect } from '../../node_modules/react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
// import LearnButton from './LearnButton'
// import IgnoreButton from './IgnoreButton'
import UpdateWordButton from './UpdateWordButton'
import translate from '../functions/translate'

function CommonWordsTableRow(props) {
    const { 
        savedTranslationsObject, 
        word, 
        sourceLanguage, 
        targetLanguage, 
        setSavedTranslationsObject, 
        wordCountObject, 
        updateWord,
        submitting
    } = props;

    const [translation, setTranslation] = useState(undefined);

    const updateTranslation = () => {
        if(savedTranslationsObject[targetLanguage] && savedTranslationsObject[targetLanguage][word]){
            setTranslation(savedTranslationsObject[targetLanguage][word]);
        } else {
            translate(word.toLowerCase(),sourceLanguage,targetLanguage,setTranslation,savedTranslationsObject,setSavedTranslationsObject);
        }
    }

    useEffect(() => {
        updateTranslation()
    },[word,sourceLanguage,targetLanguage])
    
    return (
        <Row className={'mb-1 light-grey-border-bottom'}>
            <Col xs={6} md={7}>
                <Row style={{height: '100%'}}>
                    <Col xs={12} md={6} className={'my-auto'}>
                        <b>{word}</b>
                    </Col>
                    <Col xs={12} md={6} className={'my-auto'}>
                        {translation ? translation : <Spinner animation="border" variant="secondary" size="sm" />}
                    </Col>
                </Row>
            </Col>
            <Col md={1} className={'center-text d-none d-md-block my-auto'}>
                {wordCountObject[word]}
            </Col>
            <Col xs={6} md={3} className={'no-padding-right my-auto'}>
                <ButtonGroup>
                    {/* <LearnButton 
                        word={word} 
                        savedWordsObject={savedWordsObject} 
                        setSavedWordsObject={setSavedWordsObject} 
                        sourceLanguage={sourceLanguage}
                    />
                    <IgnoreButton 
                        word={word} 
                        savedWordsObject={savedWordsObject} 
                        setSavedWordsObject={setSavedWordsObject} 
                        sourceLanguage={sourceLanguage}
                    /> */}
                    <UpdateWordButton
                        word={word} 
                        text="Learn" 
                        variant="success"
                        category="l"
                        updateWord={updateWord}
                        sourceLanguage={sourceLanguage}
                        submitting={submitting}
                    />
                    <UpdateWordButton
                        word={word} 
                        text="Ignore" 
                        variant="secondary"
                        category={"i"}
                        updateWord={updateWord}
                        sourceLanguage={sourceLanguage}
                        submitting={submitting}
                    />
                </ButtonGroup>
            </Col>
        </Row>
    );
  }

export default CommonWordsTableRow;