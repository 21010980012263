import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Button from 'react-bootstrap/Button';
import FormControl from 'react-bootstrap/FormControl';
import UnlearnButton from './UnlearnButton';
import IgnoreButton from './IgnoreButton';
import LearnButton from './LearnButton';


function DictionaryTable(props) {
    const { savedWordsObject, sourceLanguage, setSavedWordsObject } = props;
    
    const [partialSearchTerm, setPartialSearchTerm] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    
    const orderedWords = !searchTerm ? [] : Object.keys(savedWordsObject).filter(word => {
        return word.indexOf(searchTerm) !== -1;
    }).sort().sort((a,b) => {
        return savedWordsObject[a].category === savedWordsObject[b].category ? 0 : savedWordsObject[a].category < savedWordsObject[b].category ? 1 : -1;
    });

    const updateSearchTerm = (e) => {
        setSearchTerm(partialSearchTerm);
    }

    const updatePartialSearchTerm = (e) => {
        setPartialSearchTerm(e.target.value.toLowerCase());
    }

    return (
        <Container>
            <Row>
                <Col xs={12} md={8} className={'my-auto'} style={{paddingBottom: '5px'}}>
                    <FormControl placeholder="Search dictionary..." value={partialSearchTerm} onChange={updatePartialSearchTerm} />
                </Col>
                <Col xs={12} md={4} className={'my-auto'} style={{paddingBottom: '5px'}}>
                    <Button onClick={updateSearchTerm} >Search</Button>
                </Col>
            </Row>

            {searchTerm ? 
            <Row className={'mb-1 table-header'}>
                <Col>Word</Col>
            </Row>
            : null }
            <Row>
                <Col style={{overflow: 'auto', maxHeight: '55vh'}}>
                
                {orderedWords.map((word => {
                    return (
                        <Row  className={'mb-1 light-grey-border-bottom'}>
                            <Col xs={6} md={8} className={'my-auto'}><b>{word}</b></Col>
                            <Col xs={6} md={4} className={'no-padding-right my-auto'}>
                                <ButtonGroup>
                                    {savedWordsObject[word].category === 'l' ?
                                    <>
                                        <UnlearnButton 
                                            word={word}
                                            wordId={savedWordsObject[word].id} 
                                            savedWordsObject={savedWordsObject} 
                                            setSavedWordsObject={setSavedWordsObject} 
                                            sourceLanguage={sourceLanguage}
                                        />
                                        <IgnoreButton 
                                            word={word} 
                                            savedWordsObject={savedWordsObject} 
                                            setSavedWordsObject={setSavedWordsObject} 
                                            sourceLanguage={sourceLanguage}
                                        />
                                    </>
                                    :
                                    <>
                                        <UnlearnButton 
                                            word={word}
                                            wordId={savedWordsObject[word].id} 
                                            savedWordsObject={savedWordsObject} 
                                            setSavedWordsObject={setSavedWordsObject} 
                                            sourceLanguage={sourceLanguage}
                                        />
                                        <LearnButton 
                                            word={word}
                                            wordId={savedWordsObject[word].id} 
                                            savedWordsObject={savedWordsObject} 
                                            setSavedWordsObject={setSavedWordsObject} 
                                            sourceLanguage={sourceLanguage}
                                        />
                                    </>
                                    }
                                    
                                </ButtonGroup>
                            </Col>
                        </Row>
                    );
                }))}
                </Col>
            </Row>
        </Container>
    )
}

export default DictionaryTable;