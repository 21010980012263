import React, {
  useCallback,
  useRef,
  useState,
  useEffect,
} from "../../node_modules/react";
import Word from "./Word";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Spinner from "react-bootstrap/Spinner";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Overlay from "react-bootstrap/Overlay";
import Popover from "react-bootstrap/Popover";
import LearningProgressBar from "./LearningProgressBar";
import UpdateWordButton from "./UpdateWordButton";
import wordRegex from "../regex/wordRegex";
import punctuationRegex from "../regex/punctuationRegex";
import translate from "../functions/translate";
import { translateHighlight } from "../graphql/queries";
import { API, graphqlOperation } from "aws-amplify";
import { updateword } from "../graphql/queries";
import { calculatePercentageLearned } from "../functions/calculatePercentageLearned";

function Section(props) {
  const {
    text,
    sections,
    id,
    savedWordsObject,
    setSavedWordsObject,
    sourceLanguage,
    savedTranslationsObject,
    setSavedTranslationsObject,
    targetLanguage,
    popoverDirection,
    sample,
    highlight,
    setHighlight,
    mouseDown,
    setMouseDown,
    submitting,
    setSubmitting,
    setShowLimitReachedModal,
  } = props;

  const [highlightPopoverShow, setHighlightPopoverShow] = useState(false);

  useEffect(() => {
    if (highlight.section !== id) {
      setHighlightPopoverShow(false);
    }
  }, [highlight, id]);

  const words = text.match(wordRegex);

  const deleteSection = () => {
    const updatedSections = sections.filter((section) => {
      return section.id !== id;
    });
    props.setSections(updatedSections);
  };

  const getWordCategory = (word) => {
    return savedWordsObject[word.toLowerCase()]
      ? savedWordsObject[word.toLowerCase()]["category"]
      : "unknown";
  };

  const updateWord = async (word, category) => {
    setSubmitting(true);
    try {
      const lowerCase = word.toLowerCase();
      const response = await API.graphql(
        graphqlOperation(updateword, {
          input: JSON.stringify({
            id: savedWordsObject[lowerCase]
              ? savedWordsObject[lowerCase].id
              : null,
            word: lowerCase,
            category: category,
            language: sourceLanguage,
          }),
        })
      );

      const parsedResponse = JSON.parse(response.data.updateword);

      if (parsedResponse.upgradeRequired) {
        setSubmitting(false);
        setHighlight({});
        setShowLimitReachedModal(true);
        return;
      }

      const newWordId = parsedResponse.returnWordId;

      const updatedWords = JSON.parse(JSON.stringify(savedWordsObject));

      if (category) {
        updatedWords[lowerCase] = {
          category: category,
          id: newWordId,
        };
      } else {
        delete updatedWords[lowerCase];
      }

      setSavedWordsObject(updatedWords);
      setSubmitting(false);
      setHighlight({});
    } catch (e) {
      console.log(e);
      setSubmitting(false);
    }
  };

  const learnButton = (word) => (
    <UpdateWordButton
      word={word}
      text="Learn"
      variant="success"
      category="l"
      updateWord={updateWord}
      sourceLanguage={sourceLanguage}
      submitting={submitting}
    />
  );

  const unlearnButton = (word) => (
    <UpdateWordButton
      word={word}
      text="Unlearn"
      variant="danger"
      category={null}
      updateWord={updateWord}
      sourceLanguage={sourceLanguage}
      submitting={submitting}
    />
  );

  const ignoreButton = (word) => (
    <UpdateWordButton
      word={word}
      text="Ignore"
      variant="secondary"
      category={"i"}
      updateWord={updateWord}
      sourceLanguage={sourceLanguage}
      submitting={submitting}
    />
  );

  const popoverButtons = (category, word) => {
    switch (category) {
      case "l":
        return (
          <>
            {unlearnButton(word)}
            {ignoreButton(word)}
          </>
        );
      case "i":
        return (
          <>
            {learnButton(word)}
            {unlearnButton(word)}
          </>
        );
      default:
        return (
          <>
            {learnButton(word)}
            {ignoreButton(word)}
          </>
        );
    }
  };

  const ref = useRef(null);

  const HighlightPopover = (props) => {
    const { highlightPopoverShow, words } = props;
    const [translation, setTranslation] = useState(null);
    const [upgradeRequired, setUpgradeRequired] = useState(null);

    useEffect(() => {
      if (highlightPopoverShow) {
        // popoverTranslate(words.join(" "), setTranslation);
        setUpgradeRequired(null);
        const updateTranslation = async () => {
          const response = await API.graphql(
            graphqlOperation(translateHighlight, {
              input: JSON.stringify({
                targetLanguage,
                sourceLanguage,
                text: words.join(" "),
              }),
            })
          );

          const parsedResponse = JSON.parse(response.data.translateHighlight);

          if (parsedResponse.upgradeRequired) {
            setUpgradeRequired(true);
          } else {
            setTranslation(parsedResponse.data.TranslatedText);
          }
        };
        updateTranslation();
      }
    }, [words, highlightPopoverShow]);

    const onHideEvent = () => {
      setHighlightPopoverShow(false);
      setHighlight({});
    };

    return (
      <Overlay
        show={highlightPopoverShow}
        placement="bottom"
        target={ref}
        container={ref.current}
        containerPadding={20}
        rootClose={true}
        rootCloseEvent={"click"}
        onHide={onHideEvent}
      >
        <Popover id="popover-contained">
          <Popover.Title as="h3" style={{ textAlign: "center" }}>
            {words.length === 1 ? (
              translation ? (
                translation
              ) : (
                <Spinner animation="border" variant="secondary" size="sm" />
              )
            ) : (
              "Highlight Translation"
            )}
          </Popover.Title>
          <Popover.Content>
            {upgradeRequired ? (
              <span>Upgrade required</span>
            ) : words.length !== 1 ? (
              translation ? (
                translation
              ) : (
                <Spinner animation="border" variant="secondary" size="sm" />
              )
            ) : (
              popoverButtons(getWordCategory(words[0]), words[0])
            )}
          </Popover.Content>
        </Popover>
      </Overlay>
    );
  };

  const popoverTranslate = useCallback(
    (word, updateFunction) => {
      translate(
        word,
        sourceLanguage,
        targetLanguage,
        updateFunction,
        savedTranslationsObject,
        setSavedTranslationsObject
      );
    },
    [
      sourceLanguage,
      targetLanguage,
      savedTranslationsObject,
      setSavedTranslationsObject,
    ]
  );

  const mouseUpLeaveHandler = () => {
    setMouseDown(false);
    if (highlight.section !== id) {
      setHighlightPopoverShow(false);
    } else if (
      highlight.old === highlight.origin &&
      highlight.old === highlight.end
    ) {
      setHighlightPopoverShow(false);
      setHighlight({});
    } else {
      setHighlightPopoverShow(true);
    }
  };

  const mouseDownHandler = (index) => {
    const newHightlight = {
      section: id,
      origin: index,
      end: index,
      minMax: [index, index],
      old: highlight.origin,
    };
    setHighlight(newHightlight);
    setHighlightPopoverShow(false);
    setMouseDown(true);
  };

  const mouseEnterHandler = (index) => {
    if (mouseDown) {
      const newHightlight = { ...highlight };
      newHightlight.end = index;
      newHightlight.minMax = [index, highlight.origin].sort((a, b) => a - b);
      setHighlight(newHightlight);
    }
  };

  const [min, max] = highlight.minMax ? highlight.minMax : [null, null];

  const WordConstructor = (word, i) => {
    const highlighted =
      highlight.section === id &&
      i >= min &&
      i <= max &&
      highlight.end !== highlight.origin
        ? "highlighted"
        : "not-highlighted";
    if (word.match(punctuationRegex)) {
      return (
        <span key={i} className={`${highlighted} punctuation`}>
          {word}{" "}
        </span>
      );
    } else {
      const category = getWordCategory(word);
      const cachedTranslation = savedTranslationsObject[targetLanguage]
        ? savedTranslationsObject[targetLanguage][word]
        : undefined;

      const spanProps = {
        key: i,
        className: highlighted,
        style: { padding: "6px 0px" },
        onMouseDown: () => mouseDownHandler(i),
        onMouseEnter: () => mouseEnterHandler(i),
      };

      if (i === max) {
        spanProps.ref = ref;
      }

      return (
        <span {...spanProps}>
          <Word
            key={i}
            word={word}
            popoverTranslate={popoverTranslate}
            category={category}
            cachedTranslation={cachedTranslation}
            popoverDirection={popoverDirection}
          >
            {popoverButtons(category, word)}
          </Word>
        </span>
      );
    }
  };

  const percentageLearned = calculatePercentageLearned(words, savedWordsObject);

  return (
    <Alert
      variant={"secondary"}
      className={"shadow"}
      onMouseUp={mouseUpLeaveHandler}
      onMouseLeave={mouseUpLeaveHandler}
    >
      <Container>
        <Row className={"mb-1"}>
          <Col xs={12}>
            {sample ? (
              <p>
                <b>Sample text</b>
              </p>
            ) : (
              ""
            )}
            <p className="section-words">{words.map(WordConstructor)}</p>
            <HighlightPopover
              highlightPopoverShow={highlightPopoverShow}
              words={words.slice(min, max + 1)}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={9} md={10} className={"my-auto"}>
            <LearningProgressBar percentageLearned={percentageLearned} />
          </Col>
          <Col xs={3} md={2}>
            {!sample ? (
              <Button
                className={"section-delete-button"}
                variant={"secondary"}
                size={"sm"}
                onClick={deleteSection}
              >
                Delete
              </Button>
            ) : (
              ""
            )}
          </Col>
        </Row>
      </Container>
    </Alert>
  );
}

export default Section;
