import React, { useState } from "../../node_modules/react";
import Button from "react-bootstrap/Button";
import { API, graphqlOperation } from "aws-amplify";
import { updateword } from "../graphql/queries";

function LearnButton(props) {
  const [ disabled, setDisabled ] = useState(false);
  const { savedWordsObject, setSavedWordsObject, sourceLanguage, word } = props;

  const learnWord = async () => {
    setDisabled(true);
    try {
      const lowerCase = word.toLowerCase();
      const response = await API.graphql(
        graphqlOperation(updateword, { 
          input: JSON.stringify(
            { id: savedWordsObject[lowerCase] ? savedWordsObject[lowerCase].id : null, 
              word: lowerCase, category: "l", 
              language: sourceLanguage 
            }) 
          })
      );

      const newWordId = JSON.parse(response.data.updateword).returnWordId;

      const updatedWords =  JSON.parse(JSON.stringify(savedWordsObject))
      updatedWords[word.toLowerCase()] = {
        category: "l",
        id: newWordId,
      };
      setSavedWordsObject(updatedWords);
        
    } catch (e) {
      console.log(e);
      setDisabled(false);
    }
  }

  return (
    <Button disabled={disabled} variant={"success"} onClick={learnWord} data-amplify-analytics-on='click' data-amplify-analytics-name='learn-word' data-amplify-analytics-attrs={`language:${sourceLanguage}`}>
      Learn
    </Button>
  );
}

export default LearnButton;
