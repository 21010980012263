import React, { useState, useEffect } from "react";
import { Auth, Analytics, API, graphqlOperation } from "aws-amplify";
import App from "./App";
import CustomSignIn from "./authcomponents/customsignin";
import CustomSignUp from "./authcomponents/customsignup";
import CustomLoading from "./authcomponents/customLoading";
import CustomConfirmSignUp from "./authcomponents/CustomConfirmSignUp";
import CustomForgotPassword from "./authcomponents/CustomForgotPassword";
import Navigation from "./components/Navigation";
import Footer from "./components/Footer";
import { getUser } from "./graphql/queries";

import {
  Authenticator,
  AuthPiece,
  Greetings,
  SignIn,
  Loading,
  SignUp,
  ConfirmSignUp,
  ForgotPassword,
} from "aws-amplify-react";

Analytics.autoTrack("session", {
  enable: true,
});

Analytics.autoTrack("event", {
  enable: true,
});

class AppShell extends AuthPiece {
  constructor(props) {
    super(props);
    this._validAuthStates = ["signedIn"];
  }

  showComponent(theme) {
    return (
      <App
        sourceLanguage={this.props.sourceLanguage}
        targetLanguage={this.props.targetLanguage}
        premium={this.props.premium}
        view={this.props.view}
        setView={this.props.setView}
        showTutorial={this.props.showTutorial}
        setShowTutorial={this.props.setShowTutorial}
        fetchingDictionary={this.props.fetchingDictionary}
        setFetchingDictionary={this.props.setFetchingDictionary}
        currentUserData={this.props.currentUserData}
        style={{
          display: "flex",
          "flex-flow": "column",
          flex: "1 1 auto",
          "overflow-y": "auto",
        }}
      />
    );
  }
}

const signUpConfig = {
  header: "Sign up to Lingodi",
  hideAllDefaults: true,
  defaultCountryCode: "44",
  signUpFields: [
    {
      label: "Password",
      key: "password",
      required: true,
      displayOrder: 2,
      type: "password",
    },
    {
      label: "Email",
      key: "email",
      required: true,
      displayOrder: 1,
      type: "string",
    },
  ],
};

/*
class AppWithAuthenticator extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sourceLanguage: 'es',
            targetLanguage: 'en',
        }
        this.setSourceLanguage = this.setSourceLanguage.bind(this);
        this.setTargetLanguage = this.setTargetLanguage.bind(this);
    }

    setSourceLanguage = async (language) => {
        this.setState({
            sourceLanguage: language
        });
        
        let user = await Auth.currentAuthenticatedUser();
        await Auth.updateUserAttributes(user, {
            'custom:sourceLanguage': language
        });
        Auth.currentAuthenticatedUser({ bypassCache: true })
    }

    setTargetLanguage = async (language) => {
        this.setState({
            targetLanguage: language
        });
        
        let user = await Auth.currentAuthenticatedUser();
        await Auth.updateUserAttributes(user, {
            'custom:targetLanguage': language
        }); 
        Auth.currentAuthenticatedUser({ bypassCache: true })
    }
  
    render() {
    return (
      <Authenticator usernameAttributes='email' signUpConfig={ signUpConfig }
      hide={ 
        [
            Greetings,
            SignIn,
            Loading,
            SignUp,
            ConfirmSignUp,
            ForgotPassword
        ]
    }>
        <Navigation sourceLanguage={this.state.sourceLanguage} setSourceLanguage={this.setSourceLanguage} targetLanguage={this.state.targetLanguage} setTargetLanguage={this.setTargetLanguage} />
        <CustomSignUp />
        <CustomSignIn />
        <CustomConfirmSignUp />
        <CustomForgotPassword />
        <CustomLoading />
        <AppShell sourceLanguage={this.state.sourceLanguage} targetLanguage={this.state.targetLanguage}/>
        <Footer />
      </Authenticator>
    );
  }
}
*/

function AppWithAuthenticator() {
  const [sourceLanguage, setSourceLanguage] = useState("es");
  const [targetLanguage, setTargetLanguage] = useState("es");
  const [currentUserData, setCurrentUserData] = useState({});
  const [premium, setPremium] = useState(false);
  const [view, setView] = useState("app");
  const [showTutorial, setShowTutorial] = useState(false);
  const [fetchingDictionary, setFetchingDictionary] = useState(true);
  //const [ user, setUser ] = useState(null);

  useEffect(() => {
    const getUserData = async () => {
      const user = await Auth.currentAuthenticatedUser({
        bypassCache: true,
      });

      const userData = await API.graphql(
        graphqlOperation(getUser, {
          cognitoId: user.username,
        })
      );

      setCurrentUserData(userData.data.getUser);
      if (
        Number(userData.data.getUser.premiumExpiryDate) > new Date().getTime()
      ) {
        setPremium(true);
      }
    };
    getUserData();
  }, []);

  const updateSourceLanguage = async (language) => {
    setSourceLanguage(language);

    let user = await Auth.currentAuthenticatedUser();
    await Auth.updateUserAttributes(user, {
      "custom:sourceLanguage": language,
    });
    Auth.currentAuthenticatedUser({ bypassCache: true });
  };

  const updateTargetLanguage = async (language) => {
    setTargetLanguage(language);

    let user = await Auth.currentAuthenticatedUser();
    await Auth.updateUserAttributes(user, {
      "custom:targetLanguage": language,
    });
    Auth.currentAuthenticatedUser({ bypassCache: true });
  };

  return (
    <Authenticator
      usernameAttributes="email"
      signUpConfig={signUpConfig}
      hide={[Greetings, SignIn, Loading, SignUp, ConfirmSignUp, ForgotPassword]}
    >
      <Navigation
        sourceLanguage={sourceLanguage}
        setSourceLanguage={updateSourceLanguage}
        targetLanguage={targetLanguage}
        setTargetLanguage={updateTargetLanguage}
        setPremium={setPremium}
        premium={premium}
        view={view}
        setView={setView}
        setShowTutorial={setShowTutorial}
        fetchingDictionary={fetchingDictionary}
      />
      <CustomSignUp />
      <CustomSignIn />
      <CustomConfirmSignUp />
      <CustomForgotPassword />
      <CustomLoading />
      <AppShell
        sourceLanguage={sourceLanguage}
        targetLanguage={targetLanguage}
        view={view}
        setView={setView}
        premium={premium}
        showTutorial={showTutorial}
        setShowTutorial={setShowTutorial}
        fetchingDictionary={fetchingDictionary}
        setFetchingDictionary={setFetchingDictionary}
        currentUserData={currentUserData}
      />
      <Footer />
    </Authenticator>
  );
}

export default AppWithAuthenticator;
