import React from "../../node_modules/react";
import CheckoutButton from "./CheckoutButton";
import CustomerPortalButton from "./CustomerPortalButton";
import CardDeck from "react-bootstrap/CardDeck";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import tick from "../icons/tick.svg";

function StripeComponents(props) {
  const { premium, currentUserData } = props;

  const plan = currentUserData.membershipType;

  const currentPlan = () => {
    return (
      <span className={"bold-text"}>
        Current Plan{" "}
        <img
          src={tick}
          alt="tick"
          width="30"
          height="30"
          className="d-inline-block align-top"
          style={{ cursor: "pointer" }}
        />
      </span>
    );
  };

  const switchPlan = (id) => {
    return <CheckoutButton priceId={id} buttonText={"Monthly"} />;
  };

  return (
    <div>
      <CardDeck>
        <Card className="text-center">
          <Card.Header className={"bold-text"}>Free</Card.Header>
          <Card.Body>
            <Card.Title className={"double-font-size"}>0$</Card.Title>
            <Card.Text>
              <ul>
                <li>Save and access up to 300 words from your dictionary.</li>
              </ul>
            </Card.Text>
          </Card.Body>
          <Card.Footer>
            {plan === "Free" ? currentPlan() : switchPlan("REPLACE")}
          </Card.Footer>
        </Card>
        <Card className="text-center">
          <Card.Header className={"bold-text"}>Monthly</Card.Header>
          <Card.Body>
            <Card.Title className={"double-font-size"}>
              5$
              <small> / month</small>
            </Card.Title>
            <Card.Text>
              <ul>
                <li>
                  Save and access an unlimited number of words in your
                  dictionary
                </li>
                <li>Translate highlighted sections of the text</li>
              </ul>
            </Card.Text>
          </Card.Body>
          <Card.Footer>
            {plan === "Premium Monthly"
              ? currentPlan()
              : switchPlan("plan_H66XSKozfx8lmp")}
          </Card.Footer>
        </Card>
        <Card className="text-center">
          <Card.Header className={"bold-text"}>Annual</Card.Header>
          <Card.Body>
            <Card.Title className={"double-font-size"}>
              59$
              <small> / year</small>
            </Card.Title>
            <Card.Text>
              <ul>
                <li>
                  Save and access an unlimited number of words in your
                  dictionary
                </li>
                <li>Translate highlighted sections of the text</li>
              </ul>
            </Card.Text>
          </Card.Body>
          <Card.Footer>
            {plan === "Premium Annual"
              ? currentPlan()
              : switchPlan("price_1HxI5aIJjDiVvHbVO6eA5ehx")}
          </Card.Footer>
        </Card>
      </CardDeck>
      {/*premium ? 
                <CustomerPortalButton /> : 
                <>
                    
                    
                </>*/}
    </div>
  );
}

export default StripeComponents;
