import React from 'react';
import Pagination from 'react-bootstrap/Pagination'

const SectionPagination = ({sectionsPerPage, totalSections, currentPage, setCurrentPage}) => {
    
    const pageNumbers = [];
    
    const maxPageNumber = Math.ceil(totalSections / sectionsPerPage);

    if(currentPage > maxPageNumber) {
        setCurrentPage(maxPageNumber)
    }

    for(let i = 2; i <= maxPageNumber - 1; i++) {
        const min = maxPageNumber < 9 ? 1 : maxPageNumber - currentPage < 5 ? maxPageNumber - 7 : currentPage - 3;
        const max = currentPage < 5 ? 8 : currentPage + 3;

        if(i > min && i < max ){
            pageNumbers.push(
                <Pagination.Item key={i} active={i === currentPage} onClick={() => setCurrentPage(i)}>
                    {i}
                </Pagination.Item>
            );
        }
    }
    
    return (
        <>
        {maxPageNumber > 1 ? 
        <Pagination  className={'space-between'}>
            <Pagination.First  onClick={() => setCurrentPage(1)}  data-amplify-analytics-on='click' data-amplify-analytics-name='pagination'  data-amplify-analytics-attrs='page:first' />
            <Pagination.Prev disabled={currentPage === 1}  onClick={() => setCurrentPage(currentPage - 1)}  data-amplify-analytics-on='click' data-amplify-analytics-name='pagination'  data-amplify-analytics-attrs='page:previous'/>
            <Pagination.Item key={1} active={1 === currentPage} onClick={() => setCurrentPage(1)}>
                    {1}
            </Pagination.Item>
            {maxPageNumber > 9 ? 
            <>
                {currentPage > 5 ? <Pagination.Ellipsis disabled={true} /> : currentPage === 5 ? <Pagination.Item key={2} active={2 === currentPage} onClick={() => setCurrentPage(2)}  data-amplify-analytics-on='click' data-amplify-analytics-name='pagination'  data-amplify-analytics-attrs='page:numeric'>
                    {2}
                </Pagination.Item> : ''}
            </>
            : ''}

            {pageNumbers}

            {maxPageNumber > 9 ? 
            <>
                {currentPage < maxPageNumber - 4 ? <Pagination.Ellipsis disabled={true} /> : currentPage === maxPageNumber - 4 ? <Pagination.Item key={maxPageNumber - 1} active={maxPageNumber - 1 === currentPage} onClick={() => setCurrentPage(maxPageNumber - 1)} data-amplify-analytics-on='click' data-amplify-analytics-name='pagination'  data-amplify-analytics-attrs='page:numeric'>
                    {maxPageNumber - 1}
                </Pagination.Item> : ''}
                <Pagination.Item key={maxPageNumber} active={maxPageNumber === currentPage} onClick={() => setCurrentPage(maxPageNumber)} data-amplify-analytics-on='click' data-amplify-analytics-name='pagination'  data-amplify-analytics-attrs='page:numeric'>
                    {maxPageNumber}
                </Pagination.Item>
            </>
            : <Pagination.Item key={maxPageNumber} active={maxPageNumber === currentPage} onClick={() => setCurrentPage(maxPageNumber)} data-amplify-analytics-on='click' data-amplify-analytics-name='pagination'  data-amplify-analytics-attrs='page:numeric'>
                    {maxPageNumber}
                </Pagination.Item>}

            <Pagination.Next disabled={currentPage === maxPageNumber}  onClick={() => setCurrentPage(currentPage + 1)}  data-amplify-analytics-on='click' data-amplify-analytics-name='pagination'  data-amplify-analytics-attrs='page:next' />
            <Pagination.Last onClick={() => setCurrentPage(maxPageNumber)}  data-amplify-analytics-on='click' data-amplify-analytics-name='pagination'  data-amplify-analytics-attrs='page:last' />
        </Pagination>
        : null }</>
    )
}

export default SectionPagination;