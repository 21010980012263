import React, { useState } from "../../node_modules/react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import tutorial1 from "../images/tutorial_1.png";
import tutorial2 from "../images/tutorial_2.png";
import tutorial3 from "../images/tutorial_3.png";
import tutorial4 from "../images/tutorial_4.png";
import tutorial5 from "../images/tutorial_5.png";
import tutorial6 from "../images/tutorial_6.png";
import tutorial7 from "../images/tutorial_7.png";
import tutorial8 from "../images/tutorial_8.png";

function TutorialStepper(props) {
  const [step, setStep] = useState(0);

  const next = () => setStep(step + 1);
  const previous = () => setStep(step - 1);

  var stepsList = [
    {
      text: "Select the language you want to learn",
      img: tutorial1,
    },
    {
      text: "Select the language you already speak",
      img: tutorial2,
    },
    {
      text: "Copy the URL of a website in the language you would like to learn, or simply some text in that language",
      img: tutorial3,
    },
    {
      text: "Paste the URL or the text into the content adder, and wait for it to be loaded into Lingodi",
      img: tutorial4,
    },
    {
      text: "Words that aren't in your dictionary will be highlighted in red, words that are in your dictionary will be highlighted in green, words you have chosen to ignore will not be hightlighted",
      img: tutorial5,
    },
    {
      text: "Click on a word to view its translation into the language you know, and click 'Learn' to add it to your dictionary",
      img: tutorial6,
    },
    {
      text: "The most common words in the imported text that aren't in your dictionary are shown in the content explorer on the right",
      img: tutorial7,
    },
    {
      text: "You can search your dictionary ",
      img: tutorial8,
    },
  ];

  return (
    <Container>
      <Row>
        <Col xs={12} className={"tutorial-image-window"}>
          <img
            alt="select language"
            src={stepsList[step].img}
            className={"tutorial-image"}
          />
        </Col>
        <Col xs={12} className={"tutorial-text-window"}>
          {stepsList[step].text}
        </Col>
      </Row>
      <Row>
        <Col>
          {step !== 0 ? (
            <Button
              onClick={previous}
              disabled={step === 0}
              data-amplify-analytics-on="click"
              data-amplify-analytics-name="tutotorial-previous"
              data-amplify-analytics-attrs={`currentStep:${step}`}
              style={{ width: "80%" }}
            >
              Previous
            </Button>
          ) : null}
        </Col>
        <Col>
          {step !== stepsList.length - 1 ? (
            <Button
              onClick={next}
              disabled={step === stepsList.length - 1}
              data-amplify-analytics-on="click"
              data-amplify-analytics-name="tutotorial-next"
              data-amplify-analytics-attrs={`currentStep:${step}`}
              style={{ float: "right", width: "80%" }}
            >
              Next
            </Button>
          ) : null}
        </Col>
      </Row>
    </Container>
  );
}

export default TutorialStepper;
