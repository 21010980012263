import * as React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import logoAnimated from '../logoAnimated.svg';
import getLanguageIcon from '../functions/setLanguageIcon';

function fetchingDictionary(props) {
    const { sourceLanguage } = props;
    //<img alt="" src={logo} style={{height: '20vw', width: '20vw'}} className="d-inline-block align-top" />
    return (
        <Container className='window-fill gradient-background no-padding no-margin full-width'>
            <Row className="justify-content-md-center" style={{height: '100%'}}>
                <Col md={6} className={'align-self-center'}>
                    <Container className={'form-title'}>
                        <Row>
                        <Col xs={12} className={'my-auto'}>
                            <img alt="" src={logoAnimated} style={{height: '20vw', width: '20vw'}} className="d-inline-block align-top" />
                        </Col>
                        <Col xs={12}>
                            Fetching your <img alt="" src={getLanguageIcon(sourceLanguage)} style={{height: '30px', width: '30px'}} className="d-inline-block align-top" /> dictionary 
                        </Col>
                        </Row>
                    </Container>
                </Col>
            </Row>
        </Container>
    );
  }

export default fetchingDictionary;