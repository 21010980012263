import React from "react";
import { API, Auth, graphqlOperation } from "aws-amplify";
import Button from 'react-bootstrap/Button';
import { loadStripe } from "@stripe/stripe-js";
import {getUser} from '../graphql/queries';
const stripePromise = loadStripe("pk_test_o6kqKmdOwn6KdBl7thw59vbK00r5WnnRjc");

const CheckoutButton = ({ priceId, buttonText }) => {
  
  const redirectToCheckout = async () => {
    const fetchSession = async () => {
      const apiName = "stripeAPI";
      const apiEndpoint = "/checkout";

      const user = await Auth.currentAuthenticatedUser({
        bypassCache: true,
      });

      const userData = await API.graphql(
        graphqlOperation(getUser, {
          cognitoId: user.username,
        })
      );
      
      const body = {
        quantity: 1,
        client_reference_id: user.username,
        priceId: priceId,
        customerId: userData.data.getUser.stripeCustomerId
      };

      const session = await API.post(apiName, apiEndpoint, { body });
      return session; 
    };

    const session = await fetchSession();

    const sessionId = session.id;
    const stripe = await stripePromise;
    stripe.redirectToCheckout({ sessionId });
  };

  return (
    <Button variant={'success'} onClick={redirectToCheckout}>Switch To This Plan</Button>
  );
};

export default CheckoutButton;