import english from '../icons/english.png'
import spanish from '../icons/spanish.png'
import french from '../icons/french.png'
import german from '../icons/german.png'
import italian from '../icons/italian.png'
import russian from '../icons/russian.png'

const setLanguageIcon = (language) => {
    switch(language){
        case 'en':
            return english;
        case 'es':
            return spanish;
        case 'fr':
            return french;
        case 'de':
            return german;
        case 'it':
            return italian;
        case 'ru':
            return russian;
        default:
            return english;
    }
}

export default setLanguageIcon;