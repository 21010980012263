import React from '../../node_modules/react';

function Word(props) {
  const { 
    word, 
    category, 
  } = props;

  return (
      <>
        <span className={`${category} word`} >{word}</span>
        <span> </span>
      </>
  );
}

function areEqual(prevProps, nextProps) {
  return prevProps.category === nextProps.category;
}

export default React.memo(Word,areEqual);