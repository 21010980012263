import * as React from "react";
import { SignIn } from "aws-amplify-react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";
import english from "../icons/english.png";
import spanish from "../icons/spanish.png";
import french from "../icons/french.png";
import german from "../icons/german.png";
import italian from "../icons/italian.png";
import russian from "../icons/russian.png";
import "../css/brand-icon.css";
import "../css/federated-sign-in-buttons.css";

import { Auth } from "aws-amplify";

class CustomSignIn extends SignIn {
  constructor(props: any) {
    super(props);
    this._validAuthStates = ["signIn", "signedOut", "signedUp"];
    this.state = { buttonClicked: false };
  }

  preventDefaultSubmitSignIn = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.signIn();
  };

  federated = (provider) => {
    Auth.federatedSignIn({ provider });
  };

  languages = [
    { name: "English", image: english },
    { name: "Spanish", image: spanish },
    { name: "French", image: french },
    { name: "German", image: german },
    { name: "Italian", image: italian },
    { name: "Russian", image: russian },
  ];

  showComponent(theme: any) {
    return (
      <Container className="window-fill gradient-background no-padding no-margin full-width">
        <Row className="justify-content-md-center image-background">
          <Col
            xs={{ span: 12 }}
            lg={{ span: 12 }}
            xl={{ span: 7 }}
            className={"mb-5 mt-5 instructions-text"}
          >
            <h1>Learn languages from your own content</h1>
            <ul style={{ lineHeight: "3", fontSize: "1.5em" }}>
              <li>Find content that excites you</li>
              <li>Paste it into Lingodi</li>
              <li>Add the vocabulary to your personal language dictionary</li>
            </ul>
            <Button
              variant="success"
              size="lg"
              onClick={() => this.changeState("signUp")}
              disabled={this.state.buttonClicked}
            >
              Sign Up For Free
            </Button>
            <Container
              className="mb-auto"
              style={{ position: "absolute", bottom: "0" }}
            >
              <Row>
                {this.languages.map((language) => {
                  return (
                    <Col xs={4} className={"text-center mb-3"}>
                      <img
                        src={language.image}
                        alt={language.name}
                        className={"language-icon-lg"}
                      />
                      <br />
                      {language.name}
                    </Col>
                  );
                })}
              </Row>
            </Container>
          </Col>

          <Col
            xs={{ span: 12 }}
            lg={{ span: 10 }}
            xl={{ span: 4 }}
            className={"mb-4 my-auto"}
          >
            <Alert variant={"secondary"} className={"form-alert"}>
              <Form onSubmit={this.preventDefaultSubmitSignIn}>
                <h2 className={"form-title"}>Sign In</h2>
                <Form.Group controlId="formBasicEmail">
                  <Form.Label>
                    <b>Email address</b>
                  </Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter email"
                    name="email"
                    key="email"
                    onChange={this.handleInputChange}
                  />
                </Form.Group>
                <Form.Group controlId="formBasicPassword">
                  <Form.Label>
                    <b>Password</b>
                  </Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Password"
                    name="password"
                    key="password"
                    onChange={this.handleInputChange}
                  />
                </Form.Group>
                <Button
                  variant="secondary"
                  type="submit"
                  className={"mb-3 full-width-btn"}
                  disabled={this.state.buttonClicked}
                >
                  Sign In
                </Button>
                <Button
                  variant={"link"}
                  onClick={() => this.changeState("forgotPassword")}
                  disabled={this.state.buttonClicked}
                >
                  {" "}
                  Forgot your password?
                </Button>
                <Button
                  onClick={() => this.federated("Facebook")}
                  className={"facebook mb-3 full-width-btn"}
                  disabled={this.state.buttonClicked}
                >
                  <i className="facebook icon"></i>
                  Sign In with Facebook
                </Button>
                <Button
                  onClick={() => this.federated("Google")}
                  className={"google mb-3 full-width-btn"}
                  disabled={this.state.buttonClicked}
                >
                  <i className="google icon"></i>
                  Sign In with Google
                </Button>
              </Form>
            </Alert>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default CustomSignIn;
