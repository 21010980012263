/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:959da1e3-aa5f-4628-bcfe-366eeb371264",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_JRcLwxY0a",
    "aws_user_pools_web_client_id": "25cq3aenm3590pif583runnoec",
    "oauth": {
        "domain": "lingodi-dev.auth.eu-west-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://www.lingodi.com/,http://localhost:3000/",
        "redirectSignOut": "https://www.lingodi.com/,http://localhost:3000/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "FACEBOOK",
        "GOOGLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_appsync_graphqlEndpoint": "https://637kcv6xgrhttbyeb6tqn5syxe.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "stripeAPI",
            "endpoint": "https://4x1xz13xkk.execute-api.eu-west-1.amazonaws.com/dev",
            "region": "eu-west-1"
        }
    ],
    "aws_mobile_analytics_app_id": "7807b4392a5a4cf59a3e8d5e8863af98",
    "aws_mobile_analytics_app_region": "eu-west-1",
    "predictions": {
        "convert": {
            "translateText": {
                "region": "eu-west-1",
                "proxy": false,
                "defaults": {
                    "sourceLanguage": "es",
                    "targetLanguage": "en"
                }
            }
        }
    }
};


export default awsmobile;
