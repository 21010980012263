import React, { useState, useRef } from "../../node_modules/react";
import { urlfetch } from "../graphql/queries";
import sendEvent from "../functions/sendAnalyticsEvent";
import { API, graphqlOperation } from "aws-amplify";
import Alert from "react-bootstrap/Alert";
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FormControl from "react-bootstrap/FormControl";
import Overlay from "react-bootstrap/Overlay";
import Popover from "react-bootstrap/Popover";
import { v4 as uuidv4 } from "uuid";

function SectionAdder(props) {
  const { instructions, showTutorial, showLimitReachedModal } = props;
  const [loading, setLoading] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const validURL = (str) => {
    var pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // fragment locator
    return !!pattern.test(str);
  };

  async function fetchUrlSections(url) {
    try {
      const response = await API.graphql(
        graphqlOperation(urlfetch, { input: JSON.stringify({ URL: url }) })
      );

      const urlSections = JSON.parse(response.data.urlfetch).sections;

      const updatedSections = props.sections
        .concat(
          urlSections.map((paragraph) => {
            return { id: uuidv4(), text: paragraph.trim() };
          })
        )
        .filter((paragraph) => {
          return paragraph.text;
        });
      props.setSections(updatedSections);
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  }

  const paste = (e) => {
    setLoading(true);
    const pastedContent = e.clipboardData.getData("Text");

    if (validURL(pastedContent)) {
      fetchUrlSections(pastedContent);
      sendEvent("url-loaded");
    } else {
      const updatedSections = props.sections.concat(
        e.clipboardData
          .getData("Text")
          .split("\n")
          .filter((paragraph) => paragraph)
          .map((paragraph) => {
            return { id: uuidv4(), text: paragraph };
          })
      );

      sendEvent("text-loaded");

      props.setSections(updatedSections);
      setLoading(false);
    }
  };

  const deleteAllSections = () => {
    setDeleting(true);
    props.setSections([]);
    setDeleting(false);
  };

  const ref = useRef(null);

  return (
    <Alert variant={"success"} className={"shadow"} ref={ref}>
      {loading ? (
        <>
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />{" "}
          Loading
        </>
      ) : (
        <>
          <Container>
            <Row>
              <Col sm={10}>
                <FormControl
                  value={""}
                  onChange={() => ""}
                  placeholder="Paste new content here to learn it"
                  onPaste={paste}
                ></FormControl>
              </Col>
              <Col sm={2}>
                {props.sections.length > 0 ? (
                  <Button variant="secondary" onClick={deleteAllSections}>
                    {deleting ? (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    ) : (
                      "Delete All"
                    )}
                  </Button>
                ) : (
                  ""
                )}
              </Col>
            </Row>
          </Container>
        </>
      )}
      <Overlay
        show={
          instructions && !loading && !showTutorial && !showLimitReachedModal
        }
        placement="bottom"
        container={ref}
        target={ref}
        containerPadding={20}
      >
        <Popover id="popover-contained" style={{ textAlign: "center" }}>
          <Popover.Title as="h3">
            Paste content here to get started!
          </Popover.Title>
          <Popover.Content>
            This can either be <strong>text</strong> in the language you want to
            learn, or a <strong>URL</strong> linking to a webpage in that
            language
          </Popover.Content>
        </Popover>
      </Overlay>
    </Alert>
  );
}

export default SectionAdder;
