import * as React from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import { ConfirmSignUp } from 'aws-amplify-react';


export default class CustomConfirmSignUp extends ConfirmSignUp {
    constructor(props: any) {
        super(props);
        this._validAuthStates = ["confirmSignUp"];
      }

    preventDefaultSubmitSignUp = event =>{
        event.preventDefault();
        event.stopPropagation();
        this.confirm();
    }

    showComponent(theme) {
        const username = this.usernameFromAuthData();
        return (
            <Container className='window-fill gradient-background no-padding no-margin full-width'>
                <Row className="justify-content-md-center image-background" >
                    <Col sm={9}>
                        <Alert variant={'secondary'} className={'form-alert'}>
                            <h2 className={'form-title'}>Create an account</h2>
                            <Form onSubmit={this.preventDefaultSubmitSignUp}>
                                <Form.Group controlId="formBasicEmail">
                                    <Form.Label><b>Email</b></Form.Label>
                                    <Form.Control 
                                        type="email" 
                                        placeholder="Username" 
                                        name="username" 
                                        key="username" 
                                        onChange={this.handleInputChange} 
                                        value={username} 
                                    />
                                </Form.Group>
                                <Form.Group controlId="formBasicPassword">
                                    <Form.Label><b>Confirmation Code</b></Form.Label>
                                    <Form.Control type="text" placeholder="Code" name="code" key="code" onChange={this.handleInputChange}/>
                                </Form.Group>
                                <Button variant="secondary" type="submit" className={'mb-3 full-width-btn'}>
                                    Confirm Account
                                </Button>
                                <Button variant='link' onClick={this.resend}>
                                    Resend Code
                                </Button>
                            </Form>
                        </Alert>
                    </Col>
                </Row>
            </Container>
        )
    }
}