function getSampleText(language) {
    switch(language) {
        case 'de':
            return `Die deutsche Sprache bzw. Deutsch ist eine plurizentrische westgermanische Sprache, die weltweit etwa 90 bis 105 Millionen Menschen als Muttersprache und weiteren rund 80 Millionen als Zweit- oder Fremdsprache dient.`;
        case 'it':
            return `L'italiano è una lingua romanza parlata principalmente in Italia. È classificato al 21º posto tra le lingue per numero di parlanti nel mondo e, in Italia, è utilizzato da circa 58 milioni di residenti. Nel 2015 era la lingua materna del 90,4% dei residenti in Italia, che spesso lo acquisiscono e lo usano insieme alle varianti regionali dell'italiano, alle lingue regionali e ai dialetti. In Italia viene ampiamente usato per tutti i tipi di comunicazione della vita quotidiana ed è largamente prevalente nei mezzi di comunicazione nazionali, nell'amministrazione pubblica dello Stato italiano e nell'editoria.`;
        case 'ru':
            return `Ру́сский язы́к — один из восточнославянских языков, национальный язык русского народа. Является одним из наиболее распространённых языков мира — шестым среди всех языков мира по общей численности говорящих и восьмым по численности владеющих им как родным. Русский является также самым распространённым славянским языком и самым распространённым языком в Европе — географически и по числу носителей языка как родного.`;
        case 'fr':
            return `Le français est une langue indo-européenne de la famille des langues romanes. Le français s'est formé en France (variété de la « langue d'oïl », qui est la langue de la partie septentrionale du pays). Le français est déclaré langue officielle en France en 1539. Il est parlé, en 2018, sur tous les continents par environ 300 millions de personnes : 235 millions l'emploient quotidiennement et 77 millions en sont des locuteurs natifs. En 2018, 80 millions d'élèves et étudiants s'instruisent en français dans le monde. Selon l'Organisation Internationale de la Francophonie, il y aura 700 millions de francophones dans le monde en 2050.`;
        case 'es':
            return 'El español o castellano es una lengua romance procedente del latín hablado. Pertenece al grupo ibérico y es originaria de Castilla, reino medieval de la península ibérica. Se conoce también por el americanismo coloquial de castilla (por ejemplo: «hablar castilla», «entender castilla»),nota común en algunas áreas rurales e indígenas entre México, Perú y la Patagonia, pues el castellano se empezó a enseñar poco después de la incorporación de los nuevos territorios a la Corona de Castilla.';
        default:
            return 'English is a West Germanic language that was first spoken in early medieval England and eventually became a global lingua franca. It is named after the Angles, one of the Germanic tribes that migrated to the area of Great Britain that later took their name, as England. Both names derive from Anglia, a peninsula in the Baltic Sea. The language is closely related to Frisian and Low Saxon, and its vocabulary has been significantly influenced by other Germanic languages, particularly Norse (a North Germanic language), and to a greater extent by Latin and French.';
    }
}

export default getSampleText;