import { Analytics } from 'aws-amplify';

const sendEvent = (name,attributesObject) => {
  const params = {
    name: name,
  }
  
  if(attributesObject){
    params.attributes = {...attributesObject}
  }

  Analytics.record(params);
}

export default sendEvent;