import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import StripeComponents from "./StripeComponents";

function LimitReachedModal(props) {
  const {
    showLimitReachedModal,
    setShowLimitReachedModal,
    premium,
    currentUserData,
  } = props;

  const handleClose = () => setShowLimitReachedModal(false);

  return (
    <Modal
      show={showLimitReachedModal}
      onHide={handleClose}
      size="lg"
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          You have reached the free limit of words in your dictionary
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <StripeComponents premium={premium} currentUserData={currentUserData} />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default LimitReachedModal;
