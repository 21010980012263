/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const urlfetch = `query Urlfetch($input: AWSJSON!) {
  urlfetch(input: $input)
}
`;
export const translateHighlight = `query Urlfetch($input: AWSJSON!) {
  translateHighlight(input: $input)
}
`;
export const listuserwords = `query Listuserwords($input: AWSJSON!) {
  listuserwords(input: $input)
}
`;
export const updateword = `query Updateword($input: AWSJSON!) {
  updateword(input: $input)
}
`;
export const getWord = `query GetWord($id: ID!) {
  getWord(id: $id) {
    id
    word
    category
    language
    owner
  }
}
`;
export const listWords = `query ListWords(
  $filter: ModelWordFilterInput
  $limit: Int
  $nextToken: String
) {
  listWords(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      word
      category
      language
      owner
    }
    nextToken
  }
}
`;
export const getUser = `query GetUser($cognitoId: String!) {
  getUser(cognitoId: $cognitoId) {
    cognitoId
    stripeCustomerId
    premiumExpiryDate
    exceededLimit
    dictionaryWordCount
    membershipType
  }
}
`;
export const listUsers = `query ListUsers(
  $cognitoId: String
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listUsers(
    cognitoId: $cognitoId
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      cognitoId
      stripeCustomerId
      premiumExpiryDate
      exceededLimit
      dictionaryWordCount
      membershipType
    }
    nextToken
  }
}
`;
export const wordsByOwner = `query WordsByOwner(
  $owner: String
  $sortDirection: ModelSortDirection
  $filter: ModelWordFilterInput
  $limit: Int
  $nextToken: String
) {
  wordsByOwner(
    owner: $owner
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      word
      category
      language
      owner
    }
    nextToken
  }
}
`;
