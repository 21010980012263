import React, { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import logo from "../logo.svg";
import setLanguageIcon from "../functions/setLanguageIcon";
import english from "../icons/english.png";
import spanish from "../icons/spanish.png";
import french from "../icons/french.png";
import german from "../icons/german.png";
import italian from "../icons/italian.png";
import russian from "../icons/russian.png";
import help from "../icons/help.svg";
import sendEvent from "../functions/sendAnalyticsEvent";
import fetchingDictionary from "./FetchingDictionary";

function Navigation(props) {
  const {
    authData,
    setSourceLanguage,
    setTargetLanguage,
    setPremium,
    setView,
    view,
    premium,
    setShowTutorial,
    fetchingDictionary,
  } = props;

  const [attributes, setAttributes] = useState(
    authData ? authData.attributes : undefined
  );

  const getUserAttributes = async () => {
    let user = await Auth.currentAuthenticatedUser();
    setAttributes(user.attributes);
  };

  if (!attributes) {
    getUserAttributes();
  }

  const signOut = () => {
    sendEvent("sign-out");
    Auth.signOut()
      .then((data) => setAttributes(undefined))
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (attributes) {
      setSourceLanguage(
        attributes["custom:sourceLanguage"]
          ? attributes["custom:sourceLanguage"]
          : "es"
      );
      setTargetLanguage(
        attributes["custom:targetLanguage"]
          ? attributes["custom:targetLanguage"]
          : "en"
      );

      const now = new Date().getTime();

      setPremium(
        attributes["custom:premiumExpiryDate"]
          ? Number(attributes["custom:premiumExpiryDate"]) > now
            ? true
            : false
          : false
      );
    }
  }, [attributes]);

  const showTutorialModal = () => setShowTutorial(true);

  return (
    <Navbar bg="dark" variant="dark" expand="lg">
      <Navbar.Brand>
        <img
          alt=""
          src={logo}
          width="30"
          height="30"
          className="d-inline-block align-top"
        />{" "}
        Lingodi
      </Navbar.Brand>

      {attributes ? (
        <>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
              {view === "app" && !fetchingDictionary ? (
                <>
                  <NavDropdown
                    title={
                      <>
                        <span>What language are you learning</span>
                        <img
                          src={setLanguageIcon(props.sourceLanguage)}
                          alt="source"
                          className={"language-icon"}
                          style={{ marginLeft: "10px" }}
                        />
                      </>
                    }
                    className="language-select"
                  >
                    <NavDropdown.Item onClick={() => setSourceLanguage("en")}>
                      English
                      <img
                        src={english}
                        alt="english"
                        className={"language-icon"}
                        data-amplify-analytics-on="click"
                        data-amplify-analytics-name="set-source-language"
                        data-amplify-analytics-attrs="language:en"
                      />
                    </NavDropdown.Item>
                    <NavDropdown.Item onClick={() => setSourceLanguage("es")}>
                      Spanish
                      <img
                        src={spanish}
                        alt="spanish"
                        className={"language-icon"}
                        data-amplify-analytics-on="click"
                        data-amplify-analytics-name="set-source-language"
                        data-amplify-analytics-attrs="language:es"
                      />
                    </NavDropdown.Item>
                    <NavDropdown.Item onClick={() => setSourceLanguage("fr")}>
                      French
                      <img
                        src={french}
                        alt="french"
                        className={"language-icon"}
                        data-amplify-analytics-on="click"
                        data-amplify-analytics-name="set-source-language"
                        data-amplify-analytics-attrs="language:fr"
                      />
                    </NavDropdown.Item>
                    <NavDropdown.Item onClick={() => setSourceLanguage("de")}>
                      German
                      <img
                        src={german}
                        alt="german"
                        className={"language-icon"}
                        data-amplify-analytics-on="click"
                        data-amplify-analytics-name="set-source-language"
                        data-amplify-analytics-attrs="language:de"
                      />
                    </NavDropdown.Item>
                    <NavDropdown.Item onClick={() => setSourceLanguage("it")}>
                      Italian
                      <img
                        src={italian}
                        alt="italian"
                        className={"language-icon"}
                        data-amplify-analytics-on="click"
                        data-amplify-analytics-name="set-source-language"
                        data-amplify-analytics-attrs="language:it"
                      />
                    </NavDropdown.Item>
                    <NavDropdown.Item onClick={() => setSourceLanguage("ru")}>
                      Russian
                      <img
                        src={russian}
                        alt="russian"
                        className={"language-icon"}
                        data-amplify-analytics-on="click"
                        data-amplify-analytics-name="set-source-language"
                        data-amplify-analytics-attrs="language:ru"
                      />
                    </NavDropdown.Item>
                  </NavDropdown>

                  <NavDropdown
                    title={
                      <>
                        <span>What language do you speak</span>
                        <img
                          src={setLanguageIcon(props.targetLanguage)}
                          alt="target"
                          className={"language-icon"}
                          style={{ marginLeft: "10px" }}
                        />
                      </>
                    }
                    className="language-select"
                  >
                    <NavDropdown.Item onClick={() => setTargetLanguage("en")}>
                      English
                      <img
                        src={english}
                        alt="english"
                        className={"language-icon"}
                        data-amplify-analytics-on="click"
                        data-amplify-analytics-name="set-target-language"
                        data-amplify-analytics-attrs="language:en"
                      />
                    </NavDropdown.Item>
                    <NavDropdown.Item onClick={() => setTargetLanguage("es")}>
                      Spanish
                      <img
                        src={spanish}
                        alt="spanish"
                        className={"language-icon"}
                        data-amplify-analytics-on="click"
                        data-amplify-analytics-name="set-target-language"
                        data-amplify-analytics-attrs="language:es"
                      />
                    </NavDropdown.Item>
                    <NavDropdown.Item onClick={() => setTargetLanguage("fr")}>
                      French
                      <img
                        src={french}
                        alt="french"
                        className={"language-icon"}
                        data-amplify-analytics-on="click"
                        data-amplify-analytics-name="set-target-language"
                        data-amplify-analytics-attrs="language:fr"
                      />
                    </NavDropdown.Item>
                    <NavDropdown.Item onClick={() => setTargetLanguage("de")}>
                      German
                      <img
                        src={german}
                        alt="german"
                        className={"language-icon"}
                        data-amplify-analytics-on="click"
                        data-amplify-analytics-name="set-target-language"
                        data-amplify-analytics-attrs="language:de"
                      />
                    </NavDropdown.Item>
                    <NavDropdown.Item onClick={() => setTargetLanguage("it")}>
                      Italian
                      <img
                        src={italian}
                        alt="italian"
                        className={"language-icon"}
                        data-amplify-analytics-on="click"
                        data-amplify-analytics-name="set-target-language"
                        data-amplify-analytics-attrs="language:it"
                      />
                    </NavDropdown.Item>
                    <NavDropdown.Item onClick={() => setTargetLanguage("ru")}>
                      Russian
                      <img
                        src={russian}
                        alt="russian"
                        className={"language-icon"}
                        data-amplify-analytics-on="click"
                        data-amplify-analytics-name="set-target-language"
                        data-amplify-analytics-attrs="language:ru"
                      />
                    </NavDropdown.Item>
                  </NavDropdown>
                  <Navbar.Brand>
                    <img
                      onClick={showTutorialModal}
                      src={help}
                      alt="russian"
                      width="30"
                      height="30"
                      className="d-inline-block align-top"
                      style={{ cursor: "pointer" }}
                      data-amplify-analytics-on="click"
                      data-amplify-analytics-name="show-tutorial"
                    />
                  </Navbar.Brand>
                </>
              ) : null}
            </Nav>
            <Nav>
              <NavDropdown
                title={`${attributes.email}${premium ? " - Premium" : ""}`}
                id="collasible-nav-dropdown"
                className="language-select"
              >
                <NavDropdown.Item onClick={() => setView("manage-account")}>
                  Manage Your Account
                </NavDropdown.Item>
                <NavDropdown.Item onClick={signOut}>Sign Out</NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </>
      ) : (
        ""
      )}
    </Navbar>
  );
}

export default Navigation;
