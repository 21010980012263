import * as React from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import { ForgotPassword } from 'aws-amplify-react';


export default class CustomForgotPassword extends ForgotPassword {
    constructor(props: any) {
        super(props);
        this._validAuthStates = ["forgotPassword"];
        this.state = {submitView: false};
      }

    preventDefaultSubmitSendPasswordCode = event =>{
        event.preventDefault();
        event.stopPropagation();
        this.send();
        this.setState({submitView: true})
    }

    preventDefaultSubmitNewPassword = event =>{
        event.preventDefault();
        event.stopPropagation();
        this.submit();
    }

    showComponent(theme) {
        return (
            <Container className='window-fill gradient-background no-padding no-margin full-width'>
                <Row className="justify-content-md-center image-background">
                    <Col md={6}>
                        <Alert variant={'secondary'} className={'form-alert'}>
                            {!this.state.submitView ? 
                            <>
                                <h2 className={'form-title'}>First, let's find your account</h2>
                                <Form onSubmit={this.preventDefaultSubmitSendPasswordCode}>
                                    <Form.Group controlId="formBasicEmail">
                                        <Form.Label><b>Please enter your email</b></Form.Label>
                                        <Form.Control type="email" placeholder="Email" name="email" key="email" onChange={this.handleInputChange}/>
                                    </Form.Group>
                                    <Button variant="secondary" type="submit" className={'mb-3 full-width-btn'} >
                                        Find Account
                                    </Button>
                                    <p>Remembered it?
                                        <Button variant={'link'} onClick={() => this.changeState('signIn')} > Sign in here!</Button>
                                    </p>
                                </Form>
                            </>
                            :
                            <>
                                <h2 className={'form-title'}>We've sent a code to your email to confirm it's you</h2>
                                <Form onSubmit={this.preventDefaultSubmitNewPassword}>
                                    <Form.Group controlId="formBasicEmail">
                                        <Form.Label>Code</Form.Label>
                                        <Form.Control type="text" placeholder="Code" name="code" key="code" onChange={this.handleInputChange}/>
                                    </Form.Group>
                                    <Form.Group controlId="formBasicEmail">
                                        <Form.Label>New Password</Form.Label>
                                        <Form.Control type="password" placeholder="New Password" name="password" key="password" onChange={this.handleInputChange}/>
                                    </Form.Group>
                                    <Button variant="primary" type="submit" >
                                        Update New Password
                                    </Button>
                                </Form> 
                            </>
                            }
                        </Alert>
                    </Col>
                </Row>
            </Container>
            
        )
    }
}