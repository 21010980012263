import React from '../../node_modules/react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import CommonWordsTableRow from './CommonWordsTableRow';
import punctuationRegex from '../regex/punctuationRegex';
import { API, graphqlOperation } from "aws-amplify";
import { updateword } from "../graphql/queries";

function CommonWordsTable(props) {
    const { words, savedWordsObject, setSavedWordsObject, sourceLanguage, submitting, setSubmitting } = props;
    const getWordCountObject = () => {
        return words.reduce((totalsObject,currentWord) => {
            const lowerCaseWord = currentWord.toLowerCase();
            if(!savedWordsObject[currentWord.toLowerCase()] && !currentWord.match(punctuationRegex)){
                if(!totalsObject[lowerCaseWord]){
                    totalsObject[lowerCaseWord] = 0;
                }
                totalsObject[lowerCaseWord] += 1;
            }
            return totalsObject;
        },{})
    }

    const getTopTenMostCommonWords = (wordTotalsObject) => {
        const topTenWords = Object.keys(wordTotalsObject).sort((a,b) => {
            return wordTotalsObject[a] === wordTotalsObject[b] ? 0 : wordTotalsObject[a] > wordTotalsObject[b] ? -1 : 1;
        }).slice(0,9)
        return topTenWords;
    }

    const updateWord = async (word,category) => {
        setSubmitting(true);
        try {
            const lowerCase = word.toLowerCase();
            const response = await API.graphql(
              graphqlOperation(updateword, { 
                input: JSON.stringify(
                  { id: savedWordsObject[lowerCase] ? savedWordsObject[lowerCase].id : null, 
                    word: lowerCase, 
                    category: category, 
                    language: sourceLanguage 
                  }) 
                })
            );
      
            const newWordId = JSON.parse(response.data.updateword).returnWordId;
      
            const updatedWords =  JSON.parse(JSON.stringify(savedWordsObject));

            if(category){
                updatedWords[lowerCase] = {
                    category: category,
                    id: newWordId,
                };
            } else {
                delete updatedWords[lowerCase];
            }

            setSavedWordsObject(updatedWords);
            setSubmitting(false);
          } catch (e) {
            console.log(e);
            setSubmitting(false);
          }
    }

    const wordCountObject = getWordCountObject();
    const mostCommon = getTopTenMostCommonWords(wordCountObject);
    
    return (
        <Container>
            {mostCommon.length > 0 ? 
                <>
                    <Row>
                        <Col xs={12} className={'text-center'}>
                            <h3>Most common unknown words</h3>
                        </Col>
                    </Row>
                    <Row className={'mb-1 table-header'} >
                        <Col xs={6} md={7}>
                            <Row>

                                <Col xs={12} md={6}>
                                    Word
                                </Col>
                                <Col xs={12} md={6} className={'d-none d-md-block'}>
                                    Translation
                                </Col>

                            </Row>
                        </Col>

                        <Col md={1} className={'center-text d-none d-md-block'}>
                            Count
                        </Col>
                        <Col xs={6} md={3} className={'d-none d-md-block'}>
                            
                        </Col>
                    </Row>
                    {mostCommon.map((word,i) => {
                        return <CommonWordsTableRow 
                                    key={word} 
                                    word={word} 
                                    wordCountObject={wordCountObject} 
                                    savedWordsObject={props.savedWordsObject} 
                                    setSavedWordsObject={setSavedWordsObject} 
                                    sourceLanguage={sourceLanguage} 
                                    targetLanguage={props.targetLanguage}
                                    savedTranslationsObject={props.savedTranslationsObject} 
                                    setSavedTranslationsObject={props.setSavedTranslationsObject}
                                    updateWord={updateWord}
                                    submitting={submitting}
                                />
                    })
                    }
                </>
             : <Row>
                    <Col xs={12} style={{display: "grid", placeItems: "center"}}>
                        <>
                            <h3>Congratulations! 🎉 🥳</h3> 
                            <p>Looks like all these words are in your dictionary!</p>
                        </>
                    </Col>
                </Row>
                }
            
        </Container>
    );
  }

export default CommonWordsTable;