import * as React from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import { SignUp } from 'aws-amplify-react';
import NavDropdown from 'react-bootstrap/NavDropdown';
import english from '../icons/english.png'
import spanish from '../icons/spanish.png'
import french from '../icons/french.png'
import german from '../icons/german.png'
import italian from '../icons/italian.png'
import russian from '../icons/russian.png'

export default class MySignUp extends SignUp {
    constructor(props: any) {
        super(props);
        this.signUpFields =  [
              {
                label: 'Password',
                key: 'password',
                required: true,
                displayOrder: 2,
                type: 'password'
              },
              {
                label: 'Email',
                key: 'email',
                required: true,
                displayOrder: 1,
                type: 'string'
              },
              {
                label: 'Source Language',
                key: 'custom:sourceLanguage',
                required: true,
                displayOrder: 3,
                type: 'string'
              },
              {
                label: 'Target Language',
                key: 'custom:targetLanguage',
                required: true,
                displayOrder: 4,
                type: 'string'
              }
            ]
        this.state = {selectedSourceLanguage: 'es', selectedTargetLanguage: 'en'}
    }
    
    preventDefaultSubmitSignUp = event =>{
        event.preventDefault();
        event.stopPropagation();
        this.signUp();
    }

    changeSourceLanguage = (language) => {
        this.setState({selectedSourceLanguage: language});
        this.inputs["custom:sourceLanguage"] = language;
    }

    changeTargetLanguage = (language) => {
        this.setState({selectedTargetLanguage: language});
        this.inputs["custom:targetLanguage"] = language;
    }

    setLanguageIcon = (language) => {
        switch(language){
            case 'en':
                return english;
            case 'es':
                    return spanish;
            case 'fr':
                    return french;
            case 'de':
                    return german;
            case 'it':
                    return italian;
            default:
                return english;
        }
    }
    
    showComponent(theme) {
        this.inputs["custom:sourceLanguage"] = 'es';
        this.inputs["custom:targetLanguage"] = 'en';
        return (
            <Container className='window-fill gradient-background no-padding no-margin full-width'>
                <Row className="justify-content-md-center image-background" >
                    <Col md={6} className={'my-auto'}>
                        <Alert variant={'secondary'} className={'form-alert'}>
                            <h2 className={'form-title'}>Create an account</h2>
                            <Form onSubmit={this.preventDefaultSubmitSignUp}>
                                <Form.Group controlId="formBasicEmail">
                                    <Form.Label><b>Email address</b></Form.Label>
                                    <Form.Control type="email" placeholder="Enter email" name="email" key="email" onChange={this.handleInputChange}/>
                                </Form.Group>
                                <Form.Group controlId="formBasicPassword">
                                    <Form.Label><b>Password</b></Form.Label>
                                    <Form.Control type="password" placeholder="Password" name="password" key="password" onChange={this.handleInputChange}/>
                                </Form.Group>

                                <Form.Group>
                                    <NavDropdown title={<>
                                                            <span>What language are you learning?</span>
                                                            <img src={this.setLanguageIcon(this.state.selectedSourceLanguage)} alt='source' className={'language-icon-signin'} style={{"marginLeft": "10px", float: 'right'}}  />
                                                        </>} id="basic-nav-dropdown" className='language-select-lg my-auto'>
                                        <NavDropdown.Item onClick={() => this.changeSourceLanguage('en')}>English<img src={english} alt="english" className={'language-icon-signin'} /></NavDropdown.Item>
                                        <NavDropdown.Item onClick={() => this.changeSourceLanguage('es')}>Spanish<img src={spanish} alt="spanish" className={'language-icon-signin'} /></NavDropdown.Item>
                                        <NavDropdown.Item onClick={() => this.changeSourceLanguage('fr')}>French<img src={french} alt="french" className={'language-icon-signin'} /></NavDropdown.Item>
                                        <NavDropdown.Item onClick={() => this.changeSourceLanguage('de')}>German<img src={german} alt="german" className={'language-icon-signin'} /></NavDropdown.Item>
                                        <NavDropdown.Item onClick={() => this.changeSourceLanguage('it')}>Italian<img src={italian} alt="italian" className={'language-icon-signin'} /></NavDropdown.Item>
                                        <NavDropdown.Item onClick={() => this.changeSourceLanguage('ru')}>Russian<img src={russian} alt="russian" className={'language-icon-signin'} /></NavDropdown.Item>
                                    </NavDropdown>
                                </Form.Group>
                                <Form.Group>
                                    <NavDropdown title={<>
                                                            <span>What language do you speak?</span>
                                                            <img src={this.setLanguageIcon(this.state.selectedTargetLanguage)} alt='source' className={'language-icon-signin'} style={{"marginLeft": "10px", float: 'right'}}  />
                                                        </>} id="basic-nav-dropdown" className='language-select-lg my-auto'>
                                        <NavDropdown.Item onClick={() => this.changeTargetLanguage('en')}>English<img src={english} alt="english" className={'language-icon-signin'} /></NavDropdown.Item>
                                        <NavDropdown.Item onClick={() => this.changeTargetLanguage('es')}>Spanish<img src={spanish} alt="spanish" className={'language-icon-signin'} /></NavDropdown.Item>
                                        <NavDropdown.Item onClick={() => this.changeTargetLanguage('fr')}>French<img src={french} alt="french" className={'language-icon-signin'} /></NavDropdown.Item>
                                        <NavDropdown.Item onClick={() => this.changeTargetLanguage('de')}>German<img src={german} alt="german" className={'language-icon-signin'} /></NavDropdown.Item>
                                        <NavDropdown.Item onClick={() => this.changeTargetLanguage('it')}>Italian<img src={italian} alt="italian" className={'language-icon-signin'} /></NavDropdown.Item>
                                        <NavDropdown.Item onClick={() => this.changeTargetLanguage('ru')}>Russian<img src={russian} alt="russian" className={'language-icon-signin'} /></NavDropdown.Item>
                                    </NavDropdown>
                                    <Form.Text className="text-muted">
                                    You can change these at any time!
                                    </Form.Text>
                                </Form.Group>

                                <Button variant="secondary" type="submit" className={'mb-3 full-width-btn'} >
                                    Sign Up
                                </Button>
                                <p>Already have an account? 
                                    <Button variant={'link'} onClick={() => this.changeState('signIn')} > Sign in here!</Button>
                                </p>
                            </Form>
                        </Alert>
                    </Col>
                </Row>
            </Container>
            
        )
    }
}