import React, { useState, useEffect, useRef } from "react";
import Section from "./Section";
import SectionAdder from "./SectionAdder";
import SectionPagination from "./SectionPagination";

const Sections = ({
  sections,
  savedWordsObject,
  setSavedWordsObject,
  setSections,
  sourceLanguage,
  targetLanguage,
  savedTranslationsObject,
  setSavedTranslationsObject,
  sample,
  submitting,
  setSubmitting,
  setShowLimitReachedModal,
  showTutorial,
  showLimitReachedModal,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [highlight, setHighlight] = useState({});
  const [mouseDown, setMouseDown] = useState(false);
  const sectionsPerPage = 5;

  useEffect(() => {
    if (!sample) {
      setCurrentPage(1);
    }
  }, [sample]);

  useEffect(() => {
    topRef.current.scrollIntoView({ behavior: "smooth" });
  }, [currentPage]);

  const indexOfLastSection = currentPage * sectionsPerPage;
  const indexOfFirstSection = indexOfLastSection - sectionsPerPage;

  const currentSections = sections.slice(
    indexOfFirstSection,
    indexOfLastSection
  );

  const topRef = useRef();

  return (
    <div className="section-window" ref={topRef}>
      <div></div>
      {currentSections.map((section, i) => {
        return (
          <Section
            key={section.id}
            popoverDirection={i === 0 ? "bottom" : "top"}
            id={section.id}
            text={section.text}
            savedWordsObject={savedWordsObject}
            setSavedWordsObject={setSavedWordsObject}
            sections={sections}
            setSections={setSections}
            sourceLanguage={sourceLanguage}
            targetLanguage={targetLanguage}
            savedTranslationsObject={savedTranslationsObject}
            setSavedTranslationsObject={setSavedTranslationsObject}
            sample={false}
            highlight={highlight}
            setHighlight={setHighlight}
            mouseDown={mouseDown}
            setMouseDown={setMouseDown}
            submitting={submitting}
            setSubmitting={setSubmitting}
            setShowLimitReachedModal={setShowLimitReachedModal}
          />
        );
      })}

      {sections.length === 0 ? (
        <>
          <Section
            key={"SAMPLE"}
            popoverDirection={"bottom"}
            id={"SAMPLE"}
            text={sample}
            savedWordsObject={savedWordsObject}
            setSavedWordsObject={setSavedWordsObject}
            sections={sections}
            setSections={setSections}
            sourceLanguage={sourceLanguage}
            targetLanguage={targetLanguage}
            savedTranslationsObject={savedTranslationsObject}
            setSavedTranslationsObject={setSavedTranslationsObject}
            sample={true}
            highlight={highlight}
            setHighlight={setHighlight}
            mouseDown={mouseDown}
            setMouseDown={setMouseDown}
            submitting={submitting}
            setSubmitting={setSubmitting}
            setShowLimitReachedModal={setShowLimitReachedModal}
          />
          <SectionAdder
            sections={sections}
            setSections={setSections}
            instructions={sections.length === 0}
            showTutorial={showTutorial}
            showLimitReachedModal={showLimitReachedModal}
          />
          {/*<NoSections />*/}
        </>
      ) : (
        <>
          <SectionPagination
            sectionsPerPage={sectionsPerPage}
            totalSections={sections.length}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
          <SectionAdder sections={sections} setSections={setSections} />
        </>
      )}
    </div>
  );
};

export default Sections;
